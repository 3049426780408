<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Edit User" />
      </div>
      <div>
        <div class="row">
          <div class="col-md-12">
            <form novalidate @submit.prevent="validateUserInfo">
              <div class="card">
                <div class="card-header card-header-secondary">
                  <h4 class="card-title">Edit Profile</h4>
                  <p class="card-category">User information</p>
                </div>
                <div class="card-body">
                  <div class="row">
                    <label class="col-sm-2 col-form-label">First Name</label>
                    <div class="col-sm-7">
                      <div class="form-group bmd-form-group is-filled">
                        <input
                          name="first_name"
                          id="input-first_name"
                          type="text"
                          placeholder="First Name"
                          value="User"
                          v-model="uForm.first_name"
                          required
                          aria-required="true"
                          class="form-control"
                        />
                        <span
                          class="has-error"
                          v-if="!$v.uForm.first_name.required"
                          >First name is required</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Last Name</label>
                    <div class="col-sm-7">
                      <div class="form-group bmd-form-group is-filled">
                        <input
                          name="last_name"
                          id="input-last_name"
                          type="text"
                          placeholder="Last Name"
                          v-model="uForm.last_name"
                          required
                          class="form-control"
                        />
                        <span
                          class="md-error"
                          v-if="!$v.uForm.last_name.required"
                          >Last name is required</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Email</label>
                    <div class="col-sm-7">
                      <div class="form-group bmd-form-group is-filled">
                        <input
                          name="email"
                          id="input-email"
                          type="email"
                          placeholder="Email"
                          v-model="uForm.email"
                          required="required"
                          class="form-control"
                        />
                        <span class="md-error" v-if="!$v.uForm.email.required"
                          >Email address is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer ml-auto mr-auto">
                  <mdc-button :loading="loading">Save</mdc-button>
                </div>
              </div>
            </form>

            <div class="row mt-5">
              <div class="col-md-12">
                <form novalidate @submit.prevent="validatePasswordInfo">
                  <div class="card">
                    <div class="card-header card-header-secondary">
                      <h4 class="card-title">Change password</h4>
                      <p class="card-category">Password</p>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <label
                          for="input-password"
                          class="col-sm-2 col-form-label"
                          >New Password</label
                        >
                        <div class="col-sm-7">
                          <div class="form-group bmd-form-group">
                            <input
                              name="password"
                              id="input-password"
                              type="password"
                              placeholder="New Password"
                              v-model="pForm.password"
                              required
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <label
                          for="input-password-confirmation"
                          class="col-sm-2 col-form-label"
                          >Confirm New Password</label
                        >
                        <div class="col-sm-7">
                          <div class="form-group bmd-form-group">
                            <input
                              name="password_confirmation"
                              id="input-password-confirmation"
                              type="password"
                              placeholder="Confirm New Password"
                              v-model="pForm.password_confirmation"
                              required
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer ml-auto mr-auto">
                      <mdc-button :loading="loading"
                        >Change password</mdc-button
                      >
                    </div>
                  </div>
                </form>

                <form novalidate @submit.prevent="validateRoleInfo">
                  <div class="card mt-5 mb-5">
                    <div
                      class="card-header card-header-text card-header-primary"
                    >
                      <div class="card-text">
                        <h4 class="card-title">Roles</h4>
                      </div>
                    </div>
                    <div class="card-body">
                      <div
                        class="form-check"
                        style="display: flex; align-items: center"
                        v-for="role in roles"
                        :key="role.id"
                      >
                        <label class="form-check-label"></label>
                        <md-checkbox v-model="selectedRoles" :value="role.id">
                        </md-checkbox>
                        {{ role.display_name }}
                      </div>
                    </div>
                    <div class="card-footer ml-auto mr-auto">
                      <div
                        class="form-check"
                        style="display: flex; align-items: center"
                      >
                        <label class="form-check-label"></label>
                        <md-checkbox v-model="back_office_access">
                        </md-checkbox>
                        Give user back office access
                      </div>
                      <mdc-button
                        :loading="loading"
                        :disabled="selectedRoles.length < 1"
                        >Update Role</mdc-button
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("user");

export default {
  mixins: [validationMixin],
  data: () => ({
    uForm: {
      first_name: "",
      last_name: "",
      email: "",
    },
    pForm: {},
    rForm: {},
    loading: false,
    updating: false,
    roles: [],
    back_office_access: false,
    selectedRoles: [],
  }),
  validations: {
    pForm: {
      password: {
        required,
      },
      password_confirmation: {
        required,
      },
    },
    pForm: {
      password: {
        required,
      },
      password_confirmation: {
        required,
      },
    },
    uForm: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions([
      "updateUser",
      "updatePasswordInfo",
      "updateRoleInfo",
      "getUserInfo",
    ]),
    async validateUserInfo() {
      this.$v.uForm.$touch();

      if (!this.$v.uForm.$invalid) {
        try {
          this.loading = true;
          await this.updateUser({ ...this.uForm, id: this.id });
          this.toast("success", "User Updated");
          this.$router.push({ name: "User Management" });
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async validatePasswordInfo() {
      this.$v.pForm.$touch();
      if (!this.$v.pForm.$invalid) {
        try {
          this.loading = true;
          await this.updatePasswordInfo({ ...this.pForm, id: this.id });
          this.toast("success", "User Password Updated");
          this.$router.push({ name: "User Management" });
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.parseError(err);
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async validateRoleInfo() {
      try {
        this.loading = true;
        await this.updateRoleInfo({
          roles: this.selectedRoles,
          back_office_access: this.back_office_access,
          id: this.id,
        });
        this.toast("success", "User Updated");
        this.$router.push({ name: "User Management" });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.toast("error", "Opps, Something went wrong ");
      }
    },
    handleRoleChange(id) {
      this.selectedRoles.push(id);
    },
    async getInfo() {
      try {
        const { user, user_roles, roles } = await this.getUserInfo(this.id);
        this.uForm = {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        };
        this.roles = roles;
        this.selectedRoles = user_roles.map((role) => role.id);
        this.back_office_access = user.is_admin == 1 ? true : false;
      } catch {
        console.log("Opps");
      }
    },
  },
  mounted() {
    this.getInfo();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
